import React, {useState, useEffect} from "react";
import Banner from "../Secciones/Banner";
import Imagenes from "../Secciones/Imagenes";
import Videos from "../Secciones/Videos";
import Sponsors from "../Secciones/Sponsors";
import Axios from 'axios';


function MainPublic(){
    const [bannersPublic, setBannersPublic] = useState([]);
    const [imagenesPublic, setImagenesPublic] = useState([]);
    const [videosPublic, setVideosPublic] = useState([]);
    const [sponsorsPublic, setSponsorsPublic] = useState([]);
    const [urlImagesMainPublic, setUrlImgMainPublic] = useState([]);
    const [parentVideoPublic, setParentVideoPublic] = useState([]);   
    
    useEffect(() => {
      
               
        const url_host_api = (window.location.host === 'localhost:3000') ? 'http://localhost/git/itfsports_sitios/itfsports/api/' : 'https://itfsports.tv/api/';

        setParentVideoPublic((window.location.host === 'localhost:3000') ? 'localhost' : 'itfsports.tv');

        setUrlImgMainPublic((window.location.host === 'localhost:3000') ? 'http://localhost/git/itfsports_sitios/images/' : 'https://itfsports.tv/images/');
                                
            const getBannersPublic = async () => {
                await Axios.get(url_host_api+'getBannersPublic.php')
                .then((response) => {      
                    //console.table(response.data);      
                    setBannersPublic(response.data);                    
                });
            }
            

            const getSponsorsPublic = async () => {
                await Axios.get(url_host_api+'getSponsorsPublic.php')
                .then((response) => {      
                    //console.table(response.data);      
                    setSponsorsPublic(response.data);                    
                });
            }

            const getImagenesPublic = async () => {
                await Axios.get(url_host_api+'getImagenesPublic.php')
                .then((response) => {      
                    //console.table(response.data);      
                    setImagenesPublic(response.data);                    
                });
            }

            const getVideosPublic = async () => {
                await Axios.get(url_host_api+'getVideosPublic.php')
                .then((response) => {      
                    //console.table(response.data);      
                    setVideosPublic(response.data);                    
                });
            }

            getBannersPublic();  
            getImagenesPublic();    
            getVideosPublic();    
            getSponsorsPublic();
          
         
      },[]);    

    return(
        <div>
            <div className="w-100">
            <Banner banners={bannersPublic} url_path_img={urlImagesMainPublic}  />
            </div>
            <div className="container pb-20">
                <div className="row">
                    <div className="col-md-6 mt-md-10 mt-10" id="camara-1">
                        <Imagenes imagen={imagenesPublic[0]} url_path_img={urlImagesMainPublic} />
                        <Videos video={videosPublic[0]} parent_video={parentVideoPublic} url_path_img={urlImagesMainPublic}  />
                    </div>
                    <div className="col-md-6 mt-md-10 mt-10" id="camara-2">
                        <Imagenes imagen={imagenesPublic[1]} url_path_img={urlImagesMainPublic} />
                        <Videos video={videosPublic[1]} parent_video={parentVideoPublic} url_path_img={urlImagesMainPublic} />
                    </div>
                    <div className="col-md-6 mt-md-10 mt-10" id="camara-3">
                        <Imagenes imagen={imagenesPublic[2]} url_path_img={urlImagesMainPublic} />
                        <Videos video={videosPublic[2]} parent_video={parentVideoPublic} url_path_img={urlImagesMainPublic} />
                    </div>
                    <div className="col-md-6 mt-md-10 mt-10" id="camara-4"> 
                        <Imagenes imagen={imagenesPublic[3]} url_path_img={urlImagesMainPublic} />
                        <Videos video={videosPublic[3]} parent_video={parentVideoPublic} url_path_img={urlImagesMainPublic} />
                    </div>
                    <div className="col-md-6 mx-auto mt-md-10 mt-10" id="camara-5"> 
                        <Imagenes imagen={imagenesPublic[4]} url_path_img={urlImagesMainPublic}/>
                        <Videos video={videosPublic[4]} parent_video={parentVideoPublic} url_path_img={urlImagesMainPublic} />
                    </div>                
                </div>
            </div>
           <Sponsors url_path_img={urlImagesMainPublic} sponsors={sponsorsPublic} />         
        </div>     
    );
}

export default MainPublic;