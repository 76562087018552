import React, {useEffect, useState} from "react";
import 'typeface-righteous';
import Axios from 'axios';
import Header from "./components/Layout/Header";
import Footer from "./components/Layout/Footer";
import Main from "./components/Layout/Main";
import MainPublic from "./components/Layout/MainPublic";
import LoginForm from "./components/Secciones/LoginForm";
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';

function App() {
  const [user, setUser] = useState({});
  const [estadoLogin, setEstadoLogin] = useState();
  const [urlImagesApp, setUrlImgApp] = useState([]);
  const [backgroundImg, setBackgroundImg] = useState('background.jpg');
  
  const Login = details => {    

    if(details.token){
        setUser({
            id: details.id,
            usuario: details.usuario,
            email: details.email,
            token: details.token
        });
        localStorage.setItem('id', details.id)
        localStorage.setItem('usuario', details.usuario);
        localStorage.setItem('email', details.email);
        localStorage.setItem('token', details.token);                        
    }
  }
  const cerrarSesion = () => {
    setUser({});
    localStorage.removeItem('id');
    localStorage.removeItem('usuario');
    localStorage.removeItem('email');
    localStorage.removeItem('token');
    window.location.reload();
  }

  useEffect(() => {
    const token = localStorage.getItem('token');
    const id = localStorage.getItem('id');
    const usuario = localStorage.getItem('usuario');
    const email = localStorage.getItem('email');
    const url_host_api = (window.location.host === 'localhost:3000') ? 'http://localhost/git/itfsports_sitios/itfsports/api/' : 'https://itfsports.tv/api/';

    setUrlImgApp((window.location.host === 'localhost:3000') ? 'http://localhost/git/itfsports_sitios/images/' : 'https://itfsports.tv/images/');

    const getBackgroundImg = () => {
      Axios.post(url_host_api+'getBackground.php').then((response) => {            
        setBackgroundImg(response.data[0].BACKGROUND !== undefined ? response.data[0].BACKGROUND : 'background.jpg');                          
      });
    }
    getBackgroundImg();

    const getEstadoLogin = () => {
      Axios.post(url_host_api+'getEstadoLogin.php').then((response) => {            
        setEstadoLogin(response.data[0].ACTIVO);        
      });
    }
    getEstadoLogin();
    
    
    const leerToken = (token) => {
      Axios.post(url_host_api+'checkToken.php', {
          token: token,
      }).then((response) => {            
          //console.table(response.data);
          if (response.data.exito ) {
            //console.table(response.data);            
            setUser({
              id: id,
              usuario: usuario,
              email: email,
              token: token
            });
          }else{              
              setUser({});
          }
      });
    }

    if(token){
      leerToken(token);      
    }else{
      setUser({
        id: '',
        usuario: '',
        email: '',
        token: ''
      });
    }
    // Check Token
    
    const interval = setInterval(() => {      
      const token_check = localStorage.getItem('token');      
      if(token_check){        
        leerToken(token_check);
      }
    }, 5000);
    return () => clearInterval(interval);
    
  },[]);  
  
  
  return (        
    <div className="App" style={{ background: `url("${urlImagesApp}background/${backgroundImg}")  center center/cover no-repeat fixed` }}>      
      
      <Router>    
    
      {
        
      (user.token !== '') ? (
        <div>
          <Header cerrarSesion={cerrarSesion} botonSesion={true} navBar={true}/>
          <Routes>
            <Route exact path="/" element={<Main token={user.token} />}> </Route>
            <Route path="*" element={"Error 404 - Esta página no existe"}/>
          </Routes>
          <Footer />
        </div> 
      ) : (
        <div>
          {estadoLogin === '1' ? (
          <div>     
            <Routes>
              <Route exact path="/" element={<LoginForm Login={Login} />}> </Route>
              <Route path="*" element={"Error 404 - Esta página no existe"}/>
            </Routes>
            <Footer />
          </div>  
          ) : (
            <div>
            <Header cerrarSesion={cerrarSesion} botonSesion={false} navBar={true}/>
            <Routes>
              <Route exact path="/" element={<MainPublic />}> </Route>
              <Route path="*" element={"Error 404 - Esta página no existe"}/>
            </Routes>
            <Footer />
        </div> 
          )
         }  
        </div>
      )
      }       

    </Router>
      
    </div>
  );
}

export default App;
