import React, {useState, useEffect} from "react";
import { Link } from "react-router-dom";
import './Header.css';

function Header({cerrarSesion, botonSesion, navBar}){
    
    const [urlImgHeader, setUrlImgHeader] = useState([]);

    useEffect(() => {
        setUrlImgHeader((window.location.host === 'localhost:3000') ? 'http://localhost/git/itfsports_sitios/images/' : 'https://itfsports.tv/images/');         
      },[]);
      
    const handleCerrarSesion = () => {
        cerrarSesion();
    }
         
    

    return(
<div className="mb-0" id="home">
            
<div className="bg-white">
    
    <div className="landing-header" data-kt-sticky="false" data-kt-sticky-name="landing-header" data-kt-sticky-offset="{default: '100px', lg: '100px'}">
        
        <div className="container">
            
            <div className="d-flex align-items-center justify-content-between pb-2">
                
                <div className="d-flex align-items-center flex-equal">
                    
                    <button className="btn btn-icon btn-active-color-primary me-3 d-flex d-lg-none" id="kt_landing_menu_toggle">
                        
                        <span className="svg-icon svg-icon-2hx menuIcon">
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 35.26 21.17"><g id="Capa_2" data-name="Capa 2"><g id="Layer_1" data-name="Layer 1"><rect width="35.26" height="2.54" rx="1.27"/><rect y="9.31" width="35.26" height="2.54" rx="1.27"/><rect y="18.62" width="35.26" height="2.54" rx="1.27"/></g></g></svg>
                        </span>
                        
                    </button>
                    
                    <Link to="/">
                        <img alt="Logo" src={urlImgHeader +'logoheader.svg'} className="logo-default logoDesktop h-20px h-lg-25px" />
                        <img alt="Logo" src={urlImgHeader +'logoheader.svg'} className="logo-sticky logoDesktop h-20px h-lg-25px" />
                    </Link>
                    
                </div>
                {navBar === true ?
                <div className="d-lg-block" id="kt_header_nav_wrapper">
                    <div className="d-lg-block p-5 p-lg-0" data-kt-drawer="true" data-kt-drawer-name="landing-menu" data-kt-drawer-activate="{default: true, lg: false}" data-kt-drawer-overlay="true" data-kt-drawer-width="200px" data-kt-drawer-direction="start" data-kt-drawer-toggle="#kt_landing_menu_toggle" data-kt-swapper="true" data-kt-swapper-mode="prepend" data-kt-swapper-parent="{default: '#kt_body', lg: '#kt_header_nav_wrapper'}">
                        
                        <div className="menu menu-column flex-nowrap menu-rounded menu-lg-row menu-title-gray-500 menu-state-title-primary nav nav-flush fs-5 fw-bold" id="kt_landing_menu">
                            
                            <div className="menu-item">
                                
                                <a className="menu-link nav-link linkNav py-3 px-4 px-xxl-6" href="#camara-1" data-kt-scroll-toggle="true" data-kt-drawer-dismiss="true">CÁMARA 1</a>
                                
                            </div>
                            
                            <div className="menu-item">
                                
                                <a className="menu-link nav-link linkNav py-3 px-4 px-xxl-6" href="#camara-2" data-kt-scroll-toggle="true" data-kt-drawer-dismiss="true">CÁMARA 2</a>
                                
                            </div>
                            
                            <div className="menu-item">
                                
                                <a className="menu-link nav-link linkNav py-3 px-4 px-xxl-6" href="#camara-3" data-kt-scroll-toggle="true" data-kt-drawer-dismiss="true">CÁMARA 3</a>
                                
                            </div>
                            
                            <div className="menu-item">
                                
                                <a className="menu-link nav-link linkNav py-3 px-4 px-xxl-6" href="#camara-4" data-kt-scroll-toggle="true" data-kt-drawer-dismiss="true">CÁMARA 4</a>
                                
                            </div>
                           
                            <div className="menu-item">
                                
                                <a className="menu-link nav-link linkNav py-3 px-4 px-xxl-6" href="#camara-5" data-kt-scroll-toggle="true" data-kt-drawer-dismiss="true">CÁMARA 5</a>
                                
                            </div>
                            
                        </div>
                        
                    </div>
                </div>
                : ''
                }
                {botonSesion === true ? 
                <div className="flex-equal text-end ms-1">
                    <button className="btn botonHeader fs-3" onClick={handleCerrarSesion}>CERRAR SESIÓN</button>
                </div>
                : ''
                } 
            </div>
            <div className="row mt-4 pb-1">
                <div className="col-12">
                    <Link to="/">
                        <img alt="Logo" src={urlImgHeader +'logoheader.svg'} className="logo-default logoMobile mx-auto" />
                    </Link>
                </div>
            </div>
            
        </div>
        
    </div>
    
</div>


        </div>
    );
}

export default Header;