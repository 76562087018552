import React from 'react'
import './Banner.css';

function Banner(props) {
    
  return (
    <div className='w-100'>
        { props.banners !== undefined ?
            props.banners.map((banner, index) => {                
                return(
                    <a key={index} href={banner.URL_BANNER} target="_blank" rel="noreferrer" className='d-block w-100'>
                        <img src={props.url_path_img +'banners/'+ banner.IMAGEN_BANNER} alt={banner.BANNER} className={banner.BANNER === 'MOBILE' ? 'banner-mobile img-fluid' : 'banner-desktop img-fluid'}  />
                    </a>
                )
            })
        : 'Sin Banner'
        }
    </div>
  )
}

export default Banner