import React, {useState, useEffect} from "react";
import Banner from "../Secciones/Banner";
import Imagenes from "../Secciones/Imagenes";
import Videos from "../Secciones/Videos";
import Sponsors from "../Secciones/Sponsors";
import Axios from 'axios';

function Main(){
    const [banners, setBanners] = useState([]);
    const [imagenes, setImagenes] = useState([]);
    const [videos, setVideos] = useState([]);
    const [sponsors, setSponsors] = useState([]);
    const [urlImagesMain, setUrlImgMain] = useState([]);
    const [parent_video, setParentVideo] = useState([]);
    
    useEffect(() => {
        const token = localStorage.getItem('token');        
        const url_host_api = (window.location.host === 'localhost:3000') ? 'http://localhost/git/itfsports_sitios/itfsports/api/' : 'https://itfsports.tv/api/';

        setParentVideo((window.location.host === 'localhost:3000') ? 'localhost' : 'itfsports.tv');

        setUrlImgMain((window.location.host === 'localhost:3000') ? 'http://localhost/git/itfsports_sitios/images/' : 'https://itfsports.tv/images/');

        if(token){
                        
            const getBanners = async (token) => {
                await Axios.post(url_host_api+'getBanners.php', {
                    token: token,
                }).then((response) => {            
                    setBanners(response.data);                    
                });
            }

            const getSponsors = async (token) => {
                await Axios.post(url_host_api+'getSponsors.php', {
                    token: token,
                }).then((response) => {            
                    setSponsors(response.data);                    
                });
            }

            const getImagenes = async (token) => {
                await Axios.post(url_host_api+'getImagenes.php', {
                    token: token,
                }).then((response) => {
                    //console.table(response.data);            
                    setImagenes(response.data);                    
                });
            }

            const getVideos = async (token) => {
                await Axios.post(url_host_api+'getVideos.php', {
                    token: token,
                }).then((response) => {
                    //console.table(response.data);            
                    setVideos(response.data);                    
                });
            }

            getBanners(token);    
            getImagenes(token);    
            getVideos(token);    
            getSponsors(token);    
        }       
         
      },[]);    

    return(
        <div>
            <div className="w-100">
            <Banner banners={banners} url_path_img={urlImagesMain}  />
            </div>
            <div className="container pb-20">
                <div className="row">
                    <div className="col-md-6 mt-md-10 mt-10" id="camara-1">
                        <Imagenes imagen={imagenes[0]} url_path_img={urlImagesMain} />
                        <Videos video={videos[0]} parent_video={parent_video} url_path_img={urlImagesMain}  />
                    </div>
                    <div className="col-md-6 mt-md-10 mt-10" id="camara-2">
                        <Imagenes imagen={imagenes[1]} url_path_img={urlImagesMain} />
                        <Videos video={videos[1]} parent_video={parent_video} url_path_img={urlImagesMain} />
                    </div>
                    <div className="col-md-6 mt-md-10 mt-10" id="camara-3">
                        <Imagenes imagen={imagenes[2]} url_path_img={urlImagesMain} />
                        <Videos video={videos[2]} parent_video={parent_video} url_path_img={urlImagesMain} />
                    </div>
                    <div className="col-md-6 mt-md-10 mt-10" id="camara-4"> 
                        <Imagenes imagen={imagenes[3]} url_path_img={urlImagesMain} />
                        <Videos video={videos[3]} parent_video={parent_video} url_path_img={urlImagesMain} />
                    </div>
                    <div className="col-md-6 mx-auto mt-md-10 mt-10" id="camara-5"> 
                        <Imagenes imagen={imagenes[4]} url_path_img={urlImagesMain}/>
                        <Videos video={videos[4]} parent_video={parent_video} url_path_img={urlImagesMain} />
                    </div>                
                </div>
            </div>
           <Sponsors url_path_img={urlImagesMain} sponsors={sponsors} />         
        </div>     
    );
}

export default Main;