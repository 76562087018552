import React, {useState, useEffect} from "react";
import Axios from 'axios';
import Banner from "./Banner";
import './LoginForm.css';

function LoginForm({Login}) {
    const [usuario, setUsuario] = useState('');
    const [password, setPassword] = useState('');
    const [usuarioError, setUsuarioError] = useState('');
    const [passwordError, setPasswordError] = useState('');
    const [alertDanger, setAlertDanger] = useState('');
    const [urlImagesLogin, setUrlImgLogin] = useState([]);
    const [bannersPublic, setBannersPublic] = useState([]);

    const alterDangerDiv = () => {
        return <div className='alert alert-danger fade show' role="alert">Usuario o contraseña incorrectos</div>
    }

    useEffect(() => {
        setUrlImgLogin((window.location.host === 'localhost:3000') ? 'http://localhost/git/itfsports_sitios/images/' : 'https://itfsports.tv/images/');

        const url_host_api = (window.location.host === 'localhost:3000') ? 'http://localhost/git/itfsports_sitios/itfsports/api/' : 'https://itfsports.tv/api/';
        
        const getBannersPublic = () => {
            Axios.get(url_host_api+'getBannersPublic.php')
            .then((response) => {      
                //console.table(response.data);      
                setBannersPublic(response.data);                    
            });
        }
        getBannersPublic();
      },[]); 
    

    const handleSubmit = (e) => {
        e.preventDefault();
        const url_host_api = (window.location.host === 'localhost:3000') ? 'http://localhost/git/itfsports_sitios/itfsports/api/' : 'https://itfsports.tv/api/';

        if(usuario === ''){
            setUsuarioError('Ingrese su usuario');
        }else{
            setUsuarioError('');
        }
        if(password === ''){
            setPasswordError('Ingrese su contraseña');
        }else{
            setPasswordError('');
        }
        if(usuario !== '' && password !== ''){

            setUsuarioError('');
            setPasswordError('');

            Axios.post(url_host_api+'login.php', {
                usuario: usuario,
                password: password,
            }).then((response) => {            
                //console.table(response.data);
                if (response.data.token) {
                    //console.table(response.data);                
                    Login(response.data);
                }else{
                    //console.table(response.data);
                    setAlertDanger(alterDangerDiv);
                }
                
            }).catch(function (errordata) {
                //console.table(errordata);
            });
        }

        
    };


  return (    
    <div className="d-flex flex-column flex-root">

        <div className="w-100">
            <Banner banners={bannersPublic} url_path_img={urlImagesLogin}  />
        </div>
			
    <div className="d-flex flex-column flex-column-fluid bgi-position-y-bottom position-x-center bgi-no-repeat bgi-size-contain bgi-attachment-fixed mainCointainer">
        
        <div className="d-flex flex-center flex-column flex-column-fluid p-10 pb-lg-20">
            
            <div className="w-lg-500px w-300px bg-body rounded shadow-sm p-10 p-lg-15 mx-auto">
                
                <form className="form w-100" onSubmit={handleSubmit}>
                    
                    <div className="text-center mb-10">
                        
                        <h1 className="text-dark mb-3 fw-normal">BIENVENIDO</h1>
                        
                    </div>
                    
                    
                    <div className="fv-row mb-10">
                        <div className="d-flex flex-stack mb-2"> 
                            <label className="form-label fs-6 fw-normal text-dark required" htmlFor="usuario">USUARIO</label> <span className={usuarioError !== '' ? 'text-danger' : ''}>{usuarioError}</span>
                        </div>
                        <input type="text" className="form-control form-control-lg form-control-solid" id="usuario" placeholder="Usuario" name="usuario" onChange={(e) =>{
                            setUsuario(e.target.value);
                            setAlertDanger('');
                        }} />                        
                    </div>
                    
                    
                    <div className="fv-row mb-10">                        
                        <div className="d-flex flex-stack mb-2">                            
                            <label className="form-label fw-normal text-dark fs-6 mb-0 required" htmlFor="password">CONTRASEÑA</label> <span className={passwordError !== '' ? 'text-danger' : ''}>{passwordError}</span>                             
                        </div>
                        
                        <input type="password" className="form-control form-control-lg form-control-solid" id="password" placeholder="Contraseña" name="clave" autoComplete="off" onChange={(e) =>{
                            setPassword(e.target.value);
                            setAlertDanger('');
                        }} />
                                                      
                        
                    </div>
                   
                    <div className="text-center">
                        {alertDanger !== '' ? alertDanger : ''}
                        
                        <button type="submit" className="btn botonLogin border border-2 border-dark btn-lg w-100 mb-5 fs-2">INGRESAR</button>
                        
                    </div>
                    
                </form>
                
            </div>
        
        </div>				
        
    </div>
    
</div>
        
  );
}

export default LoginForm;