import React, { Fragment } from "react";
import "./Videos.css";
import ReactPlayer from "react-player";

function Videos(props) {
  return (
    <Fragment>
      {props.video !== undefined ? (
        props.video.ACTIVO === "1" ? (
          <div className="video_wrapper">
            <ReactPlayer
              url={props.video.VIDEO}
              className="react-player"
              width="100%"
              height="100%"
              controls={true}
              playing={true}
              muted={true}
            />
          </div>
        ) : (
          <div>
            <img
              src={props.url_path_img + "videos/" + props.video.IMAGEN_VIDEO}
              className="img-fluid"
              alt={props.video.VIDEO}
            />
          </div>
        )
      ) : (
        <div></div>
      )}
    </Fragment>
  );
}

export default Videos;
