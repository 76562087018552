import React, {useState, useEffect} from "react";
import './Footer.css';

function Footer(){
    const [urlImgFooter, setUrlImgFooter] = useState([]);

    useEffect(() => {
        setUrlImgFooter((window.location.host === 'localhost:3000') ? 'http://localhost/git/itfsports_sitios/images/' : 'https://itfsports.tv/images/');         
      },[]);

    return(
<footer className="mt-10">
    <div className="mb-0">
    <div className="pt-10">
        <div className="container">
            <div className="row py-10 py-lg-20">
                <div className="col-lg-6 mx-auto col-10 pe-lg-16 mb-10 mb-lg-0">
                <img src={urlImgFooter+'logofooter.svg'} alt="ITF Sports Logo" className="mb-4" />
                <h2 className="text-white fw-normal text-center mt-10" style={{fontSize: '3rem'}}><a href="mailto:info@itfsports.tv" className="text-white emailLink" >info@itfsports.tv</a></h2>
                </div>               
            </div>            
        </div>       
    </div>    
</div>

<div id="kt_scrolltop" className="scrolltop" data-kt-scrolltop={true}>
    
    <span className="svg-icon">
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
            <rect opacity="0.5" x="13" y="6" width="13" height="2" rx="1" transform="rotate(90 13 6)" fill="black" />
            <path d="M12.5657 8.56569L16.75 12.75C17.1642 13.1642 17.8358 13.1642 18.25 12.75C18.6642 12.3358 18.6642 11.6642 18.25 11.25L12.7071 5.70711C12.3166 5.31658 11.6834 5.31658 11.2929 5.70711L5.75 11.25C5.33579 11.6642 5.33579 12.3358 5.75 12.75C6.16421 13.1642 6.83579 13.1642 7.25 12.75L11.4343 8.56569C11.7467 8.25327 12.2533 8.25327 12.5657 8.56569Z" fill="black" />
        </svg>
    </span>
    
</div>

        </footer>
    );
}

export default Footer;