import React from 'react'
import './Sponsors.css';

function Sponsors(props) {

  const listaSponsors = props.sponsors.map(item =>
    <a key={item.ID} href={item.URL_SPONSOR} target='_blank' rel='noopener noreferrer' className='col mt-10'><div className='imageDiv mx-auto'><img src={props.url_path_img +'sponsors/'+item.IMAGEN_SPONSOR} alt={item.SPONSOR}/></div></a>       
  );

  return (
    <div className='container pt-md-10'>
        <div className='row row-cols-3 row-cols-md-6 mx-auto justify-content-center sponsorsDiv'>
          {listaSponsors}     
        </div>
    </div>
  )
}

export default Sponsors