import React from 'react'
import './Imagenes.css';

function Imagenes(props) {
    
  return (
    <div className='w-100'>
       {props.imagen !== undefined ? 
       <img src={props.url_path_img +'imagenes/'+props.imagen.IMAGEN} alt={"Posición " + props.imagen.POSICION} className='imagenCamara img-fluid'  />
       : ''
       }
    </div>
  )
}

export default Imagenes